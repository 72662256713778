import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="mdx-content">
      <h1 className="page__title">Regulamin</h1>
      <p><strong>REGULAMIN</strong></p>
      <h2>§ 1<br /> DEFINICJE</h2>
      <ol>
        <li>Na potrzeby niniejszego regulaminu przyjmuje się następujące definicje:</li>
        <ol>
          <li><strong>regulamin</strong> - niniejszy dokument stanowiący wzorzec umowny w rozumieniu art. 384 § 1 kc;</li>
          <li><strong>platforma edukacyjna</strong> - serwis internetowy dostępny w sieci web pod adresem <a href="https://edu.devstyle.pl">https://edu.devstyle.pl</a> będący usługą w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;</li>
          <li><strong>usługodawca</strong> – DEVSTYLE spółka z ograniczona odpowiedzialnością z siedzibą w Białymstoku (15-215) przy ul. Konopnickiej 14/8, NIP: 5423453088 (dawniej Maciej Aniserowicz prowadzący jednoosobową działalność gospodarczą, NIP 5422824401); adres e-mail <a href="mailto:sklep@devstyle.pl">sklep@devstyle.pl</a>;</li>
          <li><strong>sklep</strong> – serwis internetowy umożliwiający nabywanie od usługodawcy towarów i usług, dostępny w sieci web pod adresem <a href="https://sklep.devstyle.pl">https://sklep.devstyle.pl</a> i <a href="https://devstyle.salescrm.pl">https://devstyle.salescrm.pl</a>;</li>
          <li><strong>klient</strong> - to osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna lub jednostka organizacyjna nie posiadająca osobowości prawnej, która nabyła w sklepie usługę, do której dostęp jest możliwy poprzez platformę edukacyjną; w przypadku gdy klient korzysta z platformy edukacyjnej, jest równocześnie użytkownikiem;</li>
          <li><strong>użytkownik</strong> - osoba fizyczna, której zostanie przyznany dostęp do platformy edukacyjnej, wskazana przez klienta, który kupił usługę; użytkownik nie musi być klientem;</li>
          <li><strong>umowa</strong> – stosunek prawny, który powstaje pomiędzy usługodawcą a usługobiorcą w skutek akceptacji regulaminu przez użytkownika, będący w istocie umową o świadczenie usługi w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, polegającą na możliwości korzystania z konta użytkownika;</li>
          <li><strong>konto użytkownika</strong> – zbiór zasobów i uprawnień przysługujących użytkownikowi w ramach platformy edukacyjnej wyłącznie w trakcie trwania umowy;</li>
          <li><strong>usługa warunkowa</strong> - usługa oparta na dostępie warunkowym w rozumieniu ustawy o ochronie niektórych usług świadczonych drogą elektroniczną opartych lub polegających na dostępie warunkowym, będąca jednocześnie usługą w rozumieniu ustawy o świadczeniu usług drogą elektroniczną polegająca na możliwości skorzystania w ramach platformy edukacyjnej z dostępu za pomocą konta użytkownika do treści cyfrowych stanowiących własność usługodawcy, na którą składa się szereg funkcjonalności wskazanych w § 3 ust. 1 regulaminu, możliwa do nabycia za pośrednictwem sklepu.</li>
        </ol>
        <li>We wszelkich sprawach związanych z platformą edukacyjną klient może kontaktować się z usługodawcą osobiście, za pośrednictwem wiadomości e-mail wysłanej na adres <a href="mailto:edu@devstyle.pl">edu@devstyle.pl</a>.</li>
      </ol>
      <h2>§ 2<br /> PRZEDMIOT I ZAWARCIE UMOWY</h2>
      <ol>
        <li>Usługodawca w ramach prowadzonej przez siebie działalności gospodarczej umożliwia dostęp do konta użytkownika za pomocą publicznej sieci telekomunikacyjnej, oferując możliwość korzystania z niego użytkownikom na warunkach określonych w regulaminie, wyłącznie po jego akceptacji co skutkuje zawarciem umowy.</li>
        <li>Dostęp do konta użytkownika odbywa się na żądanie użytkownika z dowolnego państwa świata.</li>
        <li>Skorzystanie z konta użytkownika jest możliwe wyłącznie przez użytkownika, który:</li>
        <ol>
          <li>jako klient nabył w sklepie usługę dostępu do treści cyfrowych stanowiących własność usługodawcy i podczas tego procesu zaakceptował regulamin platformy edukacyjnej;</li>
          <li>został wskazany przez klienta jako użytkownik, zostało dla niego utworzone konto użytkownika i zaakceptował regulamin platformy edukacyjnej.</li>
        </ol>
        <li>Utworzenie konta użytkownika na platformie edukacyjnej następuje:</li>
        <ol>
          <li>automatycznie, z chwilą nabycia usługi w sklepie;</li>
          <li>po przekazaniu przez klienta danych niezbędnych do utworzenia konta użytkownika odpowiadających ilości zakupionych usług dostępu warunkowego – tj. imienia, nazwiska i adresu e-mail użytkownika.</li>
        </ol>
        <li>Doręczenie treści regulaminu następuje:</li>
        <ol>
          <li>w przypadku zawierania umowy pomiędzy usługodawcą a klientem - w chwili zawarcia umowy, za pomocą wiadomości wysłanej na adres e-mail wskazany przez klienta;</li>
          <li>w przypadku zawierania umowy pomiędzy usługodawcą a użytkownikiem - w chwili utworzenia konta użytkownika, za pomocą wiadomości wysłanej na adres e-mail przypisany do tworzonego konta użytkownika, wraz z informacją o utworzeniu konta.</li>
        </ol>
        <li>Akceptacja regulaminu platformy edukacyjnej skutkuje zawarciem umowy:</li>
        <ol>
          <li>pomiędzy usługodawcą a klientem w chwili zakupu usługi warunkowej w sklepie;</li>
          <li>pomiędzy usługodawcą a użytkownikiem w chwili pierwszego uwierzytelnienia się na koncie użytkownika – zalogowanie się na konto oznacza akceptację regulaminu.</li>
        </ol>
        <li>Klientowi przysługuje prawo do niezaakceptowania regulaminu platformy edukacyjnej. W takim przypadku jest on zobowiązany powiadomić usługodawcę o tym w drodze wiadomości e-mail wysłanej na adres <a href="mailto:edu@devstyle.pl">edu@devstyle.pl</a>.</li>
        <li>Klientowi przysługuje prawo do przekazania przypisanego do niego dostępu do usługi warunkowej dostępnej na platformie edukacyjnej na rzecz innego wskazanego przez niego użytkownika. Aby skorzystać z tego prawa, klient jest zobowiązany napisać wiadomość e-mail na adres <a href="mailto:edu@devstyle.pl">edu@devstyle.pl</a>, posługując się adresem e-mail przypisanym do konta na platformie edukacyjnej i wskazać osobę, na rzecz której ma nastąpić przeniesienie usługi. Prawo do przekazania dostępu do usługi warunkowej innemu użytkownikowi przez klienta jest możliwe do zrealizowania w ciągu 14 kolejnych dni od dnia zakupu usługi. Prawo to przysługuje wyłącznie użytkownikowi, który jednocześnie jest klientem.</li>
        <li>Umowa jest realizowana nieodpłatnie, na czas nieoznaczony.</li>
        <li>Porozumiewanie się ze sprzedawcą w związku z zawartą umową jest możliwe w drodze korespondencji e-mail, korespondencji pocztowej lub kontaktu osobistego.</li>
      </ol>
      <h2>§ 3<br /> FUNKCJONOWANIE I BEZPIECZEŃSTWO PLATFORMY EDUKACYJNEJ</h2>
      <ol>
        <li>Platforma edukacyjna jest serwisem typu <em>Software as a Service</em>. Sposób jej działania nie wymaga specjalnej konfiguracji na urządzeniu użytkownika.</li>
        <li>Platforma edukacyjna stanowi zestaw funkcjonalności tworzących odrębną przestrzeń roboczą dla każdego użytkownika, która umożliwia korzystanie z zakupionych w sklepie usług warunkowych.</li>
        <li>Dostęp do platformy edukacyjnej jest możliwy wyłącznie za pomocą konta użytkownika.</li>
        <li>Każde konto użytkownika posiada unikalny identyfikator odpowiadający adresowi e-mail wykorzystanemu przez użytkownika. Do każdego konta użytkownika przypisane jest hasło będące unikalnym ciągiem znaków alfanumerycznych. Identyfikator i hasło są wykorzystywane w celu uwierzytelnienia użytkownika i umożliwienia mu dostępu do konta użytkownika platformy edukacyjnej.</li>
        <li>Usługodawca nigdy nie prosi użytkowników platformy edukacyjnej o udostępnienie w jakikolwiek sposób hasła konta użytkownika. Hasło winno być unikalne i wykorzystywane wyłącznie w celu uwierzytelniania się na platformie edukacyjnej.</li>
        <li>Usługodawca może – ze względów bezpieczeństwa – wymusić na użytkowniku zmianę hasła dostępu do platformy edukacyjnej. O konieczności zmiany hasła użytkownik poinformowany zostanie za pośrednictwem poczty e-mail.</li>
        <li>Platforma edukacyjna została zbudowana z założeniem, iż każda osoba fizyczna posiada własne, indywidualne konto użytkownika, za pośrednictwem którego uzyskuje dostęp do określonych usług warunkowych zakupionych w sklepie. Platforma edukacyjna nie jest przeznaczona do wykorzystywania jednego konta użytkownika przez więcej niż jedną osobę fizyczną.</li>
        <li>Korzystanie z jednego konta użytkownika platformy edukacyjnej przez więcej niż jedną osobę lub podmiot stanowi podstawę do stosownej rekalkulacji opłat pobieranych przez usługodawcę od klienta lub użytkownika - zgodnie z warunkami sprzedaży szkolenia w sklepie.</li>
        <li>Platforma edukacyjna udostępnia użytkownikowi możliwość wykorzystywania następujących funkcjonalności:</li>
        <ol>
          <li>korzystania z wykupionej w sklepie usługi warunkowej umożliwiającej dostęp do treści cyfrowej;</li>
          <li>zmiana danych personalnych oraz hasła.</li>
        </ol>
        <li>Usługobiorca może spotkać się z szeregiem zagrożeń związanych z korzystaniem z platformy edukacyjnej. Należy do nich zaliczyć w szczególności możliwe do wystąpienia próby stosowania ataków typu <em>phishing</em>, <em>Man-in-the-Middle </em>czy choćby <em>session hijacking</em>. Usługodawca nie przesyła za pośrednictwem poczty elektronicznej hasła dostępu do konta użytkownika platformy edukacyjnej oraz nie prosi za pomocą jakiegokolwiek kanału o udostępnienie mu tych danych.</li>
      </ol>
      <h2>§ 4<br /> WYMAGANIA TECHNICZNE NIEZBĘDNE DO WSPÓŁPRACY Z SERWISEM</h2>
      <ol>
        <li>Aby móc bez zakłóceń korzystać z platformy edukacyjnej i dostępnych na niej usług warunkowych, użytkownik zobowiązany jest korzystać z końcowego urządzenia telekomunikacyjnego spełniającego następujące warunki techniczne:
          <ol>
            <li>Mozilla Firefox – wersja 70;</li>
            <li>Google Chrome – wersja 78;</li>
          </ol>
        </li>
        <ol>
          <li>zainstalowana przynajmniej jedna z przeglądarek internetowych w następującej wersji:</li>
          <li>zainstalowana aplikacja umożliwiająca odczytywanie plików w standardzie PDF – wersji co najmniej 1.4 – Adobe Acrobat Reader DC wersja 11;</li>
          <li>możliwość podłączenia urządzenia do publicznej sieci telekomunikacyjnej za pomocą łącza o przepustowości wynoszącej co najmniej 20 mbps;</li>
          <li>możliwość odtwarzania dźwięku na urządzeniu;</li>
          <li>procesor zbudowany z co najmniej dwóch rdzeni o zegarze nie mniejszym niż 2 GHz;</li>
          <li>pamięć operacyjna RAM wynosząca co najmniej 4 GB;</li>
          <li>wyświetlacz o rozdzielczości nie niższej niż 1024 na 768 pikseli oraz przekątnej nie mniejszej niż 7 cali.</li>
        </ol>
        <li>Użytkownik może próbować korzystać z platformy edukacyjnej za pomocą urządzeń niespełniających wyżej wymienionych standardów, lecz usługodawca nie ponosi odpowiedzialności za możliwe problemy, zakłócenia lub inne niedogodności.</li>
        <li>Usługodawca nie wdrożył dodatkowych technicznych środków ochrony treści cyfrowych.</li>
      </ol>
      <h2>§ 5<br /> PRAWA I OBOWIĄZKI USŁUGODAWCY I UŻYTKOWNIKA</h2>
      <ol>
        <li>Usługodawca zapewnia użytkownikowi działanie platformy edukacyjnej w sposób uniemożliwiający dostęp osób nieuprawnionych do treści przekazu składającego się na tę usługę, w szczególności przy wykorzystaniu certyfikatu SSL oraz innych środków organizacyjnych i technicznych, które gwarantują należyty poziom bezpieczeństwa.</li>
        <li>Użytkownik uprawniony jest do korzystania z zasobów platformy edukacyjnej w sposób zgodny z obowiązującym prawem, w szczególności z poszanowaniem przepisów o ochronie danych osobowych.</li>
        <li>Zakazane jest wprowadzanie do platformy edukacyjnej przez użytkownika treści o charakterze bezprawnym.</li>
        <li>Użytkownik ponosi pełną odpowiedzialność za szkody powstałe w wyniku korzystania z platformy edukacyjnej w sposób niezgodny z prawem lub regulaminem.</li>
      </ol>
      <h2>§ 6<br /> DANE OSOBOWE</h2>
      <ol>
        <li>Usługodawca gwarantuje ochronę wszelkich zebranych danych osobowych. Dane osobowe użytkownika są przetwarzane przez usługodawcę wyłącznie w celach związanych z działaniem platformy edukacyjnej. Usługodawca daje możliwość zapoznania się z treścią obowiązku informacyjnego udostępniając go klientowi w chwili zawarcia umowy, a użytkownikowi w chwili utworzenia jego konta użytkownika, w drodze wiadomości e-mail, o której mowa w § 2 ust. 5. Dodatkowo, treść obowiązku informacyjnego jest dostępna pod adresem https://edu.devstyle.pl/pages/polityka-prywatnosci i https://devstyle.pl/rodo/.</li>
        <li>Klient udostępniając usługodawcy dane osobowe użytkowników oświadcza, że posiada uprawnienia i podstawę prawną do przetwarzania za pomocą platformy edukacyjnej ujawnianych przez siebie danych w sposób zgodny z prawem, w szczególności z wyłączeniem naruszenia praw osób trzecich.</li>
        <li>Informacja na temat postępów użytkownika w realizowaniu materiału składającego się na szkolenie może być udostępniana przez usługodawcę podmiotowi trzeciemu, od którego użytkownik uzyskał uprawnienie do przypisania mu usługi warunkowej. Udostępnianie takie odbywa się tylko w przypadku istnienia umowy pomiędzy usługodawcą a podmiotem trzecim przewidującej taki obowiązek usługodawcy.</li>
      </ol>
      <h2>§ 7<br /> ODPOWIEDZIALNOŚĆ USŁUGODAWCY</h2>
      <ol>
        <li>Usługodawca nie ponosi żadnej odpowiedzialności względem klienta i użytkownika za jakiekolwiek skutki udostępnienia przez użytkownika loginu lub hasła do platformy edukacyjnej jakiejkolwiek innej osobie. Usługodawca nie ponosi odpowiedzialności w szczególności w sytuacji, w której do udostępnienia identyfikatora lub hasła użytkownika dojdzie na skutek działania złośliwego oprogramowania na końcowym urządzeniu telekomunikacyjnym użytkownika platformy edukacyjnej.</li>
        <li>Usługodawca nie ponosi odpowiedzialności za jakiekolwiek decyzje klienta lub użytkownika, skutki tych decyzji ani za szkody spowodowane działaniem klienta lub użytkownika, jego pracowników lub doradców podejmowane na podstawie treści zamieszczonych na platformie edukacyjnej. Wyłączenie to nie rozszerza w żadnej mierze dozwolonego zakresu korzystania z platformy.</li>
        <li>Treści udostępnione użytkownikowi w ramach platformy edukacyjnej nie mogą być traktowane jako informacje stanowiące jakąkolwiek formę doradztwa.</li>
        <li>Odpowiedzialność usługodawcy z tytułu działania platformy edukacyjnej jest ograniczona do strat poniesionych przez klienta lub użytkownika wynikających z błędnego działania platformy edukacyjnej bądź niewywiązania się z umowy w inny, zawiniony przez usługodawcę sposób, a jej wysokość jest ograniczona do wysokości wynagrodzenia uiszczonego za tę usługę warunkową, której działanie było błędne lub nieprawidłowe. Odpowiedzialność usługodawcy nie obejmuje utraconych korzyści klienta lub użytkownika.</li>
      </ol>
      <h2>§ 8<br /> POSTĘPOWANIE REKLAMACYJNE</h2>
      <ol>
        <li>Użytkownikowi przysługuje prawo zgłaszania reklamacji dotyczących funkcjonowania platformy edukacyjnej, sposobu wykonania umowy, świadczenia usługi oraz usług warunkowych za pośrednictwem poczty elektronicznej wysyłając wiadomość na <a href="mailto:edu@devstyle.pl">edu@devstyle.pl</a>.</li>
        <li>Użytkownik będący konsumentem ma prawo zgłaszać reklamacje w terminie 2 lat od chwili wystąpienia zdarzenia, które ma być przedmiotem reklamacji. Użytkownik niebędący konsumentem lub przedsiębiorca o statusie zbliżonym do konsumenta ma prawo zgłaszać reklamacje w terminie 7 dni od chwili wystąpienia zdarzenia, które ma być przedmiotem reklamacji.</li>
        <li>Zgłoszenie reklamacji powinno zawierać oznaczenie użytkownika platformy edukacyjnej (imię i nazwisko) albo klienta (imię i nazwisko lub nazwa podmiotu będącego osobą prawną), adres korespondencyjny albo adres siedziby, adres poczty elektronicznej. Zgłoszenie powinno także w sposób zrozumiały i zwięzły opisywać przyczynę reklamacji oraz być wysłane z adresu e-mail przypisanego do konta użytkownika platformy edukacyjnej albo adresu e-mail, którym posługiwał się klient w chwili zakupu dostępu do usługi warunkowej będącej przedmiotem reklamacji.</li>
        <li>Usługodawca może wezwać wnoszącego reklamację do jej uzupełnienia, jeśli przesłane mu zgłoszenie okaże się niewystarczające i usługodawca nie będzie w stanie odnieść się do wnoszonej reklamacji. Wnoszący reklamację w takim przypadku uzupełni ją w terminie 30 kolejnych dni licząc od dnia, w którym zostanie wezwany do takiego uzupełnienia, pod rygorem pozostawienia reklamacji bez rozpoznania przez usługodawcę. Brak uzupełnienia reklamacji będzie równoznaczny ze zrzeczeniem się przez zgłaszającego reklamację wszelkich roszczeń względem usługodawcy a związanych z nieprawidłowo zgłoszoną reklamacją.</li>
        <li>Prawidłowo zgłoszona reklamacja zostanie rozpatrzona przez usługodawcę w terminie 14 dni od dnia jej otrzymania lub uzupełnienia (jeśli było ono konieczne).</li>
        <li>Informacja o uwzględnieniu bądź nieuwzględnieniu reklamacji zostanie dostarczona do wnoszącego reklamację za pośrednictwem wiadomości wysłanej na adres poczty elektronicznej przypisany do konta użytkownika.</li>
      </ol>
      <h2>§ 8<br /> POSTANOWIENIA KOŃCOWE</h2>
      <ol>
        <li>Jakiekolwiek informacje zamieszczane na platformie edukacyjnej nie mogą być traktowane jako informacje stanowiące jakąkolwiek formę doradztwa. Usługodawca nie ponosi odpowiedzialności za jakiekolwiek decyzje podjęte przez klienta lub użytkownika na podstawie informacji dostępnych za pomocą konta użytkownika, w tym na podstawie treści poszczególnych usług warunkowych – również tych dotyczących wymogów prawnych.</li>
        <li>Wszelkie spory wynikłe na podstawie regulaminu, związane z jakąkolwiek kwestią poruszaną przez regulamin bądź wynikającą ze stosunku umownego pomiędzy usługodawcą i użytkownikiem niebędącym konsumentem lub przedsiębiorcą o statusie zbliżonym do konsumenta w rozumieniu przepisów kodeksu cywilnego, będą rozstrzygane przez sądy powszechne właściwe miejscowo dla siedziby usługodawcy.</li>
        <li>Korzystanie z usługi warunkowej ma charakter akcesoryjny w stosunku do umowy, na podstawie której nastąpiła jej nabycie oraz do niniejszej umowy. W przypadku rozwiązania lub odstąpienia od umowy nabycia dostępu warunkowego usługodawca nie jest zobligowany do świadczenia usługi dostępu do platformy edukacyjnej i może rozwiązać umowę, składając odpowiednie oświadczenie woli w drodze wiadomości e-mail wysłanej na adres przypisany do konta użytkownika, bez konieczności zachowania jakiegokolwiek terminu wypowiedzenia.</li>
        <li>W przypadku naruszania postanowień regulaminu usługodawca jest uprawniony wezwać za pomocą wiadomości e-mail wysłanej na adres przypisany do konta użytkownika do zaprzestania naruszania, wskazując 7-dniowy termin na jego zaprzestanie. W przypadku bezskutecznego upływu tego terminu, usługodawca jest uprawniony do zaprzestania świadczenia usługi dostępu do platformy edukacyjnej.</li>
        <li>W przypadku, o którym mowa w ust. 4 powyżej użytkownikowi niebędącemu konsumentem lub przedsiębiorcom o statusie zbliżonym do konsumenta, nie przysługuje względem usługodawcy jakiekolwiek roszczenie o charakterze odszkodowawczym.</li>
        <li>Umowa nie może być przedmiotem cesji ani też nie może zostać zbyta na rzecz jakiejkolwiek innej osoby przez użytkownika.</li>
        <li>Regulamin obowiązuje:</li>
        <ol>
          <li>od 22 stycznia 2021 r. wszystkich nowych użytkowników platformy edukacyjnej;</li>
          <li>od 22 lutego 2021 r. wszystkich dotychczasowych użytkowników platformy edukacyjnej.</li>
        </ol>
        <li>Usługodawca zastrzega sobie prawo zmiany treści regulaminu. W takim przypadku poinformuje użytkownika za pośrednictwem wiadomości wysłanej na adres poczty elektronicznej o nowym brzmieniu regulaminu z co najmniej 30-dniowym wyprzedzeniem. Termin 30 dni na zmianę regulaminu nie obowiązuje w przypadkach, w których zmiana regulaminu jest wymuszona przez zmianę prawa i czas wejścia w życie przepisów ją wymuszających nie daje możliwości uzyskania takiego wyprzedzenia. Użytkownikowi przysługuje prawo do niezaakceptowania regulaminu w nowym brzmieniu – w tym wypadku powinien zgłosić sprzeciw w drodze wiadomości e-mail wysłanej do usługodawcy. Wniesienie sprzeciwu będzie skutkowało rozwiązaniem umowy z dniem rozpoczęcia stosowania nowego regulaminu.</li>
      </ol>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      